var walt;

walt = angular.module('WaltWoodland', ['vcRecaptcha']);

walt.directive('showonscroll', [
  '$window', function($window) {
    var scroll;
    scroll = void 0;
    scroll = {
      restrict: 'A',
      link: function($scope) {
        angular.element($window).bind('scroll', function() {
          if (this.pageYOffset >= 250) {
            $scope.showElement = true;
          } else {
            $scope.showElement = false;
          }
          $scope.$apply();
        });
      }
    };
    return scroll;
  }
]);

walt.controller('MainController', [
  '$scope', '$timeout', 'vcRecaptchaService', '$http', '$location', function($scope, $timeout, vcRecaptchaService, $http, $location) {
    var _mcThis;
    _mcThis = void 0;
    _mcThis = this;
    $scope.$watch((function() {
      return $location.path();
    }), function(val) {
      if (val !== '') {
        _mcThis.goTo(val.substr(1));
      }
      return true;
    });
    this.stones = [
      {
        title: 'Rounds',
        img: 'imgs/rocks/rounds.jpg',
        text: '6" to 12" rock with moss & lichen for use of landscaping in Water Falls, Dry Ponds, Flower Beds, etc.'
      }, {
        title: 'Ledger / Mason',
        img: 'imgs/rocks/ledger-mason-rock.jpg',
        text: 'Comprised mostly of sandstone, ledger or "mason" rock is smooth and mostly uniform in size and colors. Years of pressure and weather makes this rock perfect for stack stone applications.'
      }, {
        title: 'Dry Stackers',
        img: 'imgs/rocks/dry-stackers.jpg',
        text: 'Used for retaining walls or other landscape needs.'
      }, {
        title: 'Small Patio Slab',
        img: 'imgs/rocks/small-patio-slab.jpg',
        text: 'For walkway stone inside & outside. Can mix with large patio slab.'
      }, {
        title: 'Large Patio Slab',
        img: 'imgs/rocks/large-patio-slab.jpg',
        text: 'Cut on three sides to fit in with small patio slabs.'
      }, {
        title: 'Stair Treads',
        img: 'imgs/rocks/stair-treads.jpg',
        text: 'Length up to 8 feet. Step height is within building codes.'
      }, {
        title: 'Large Wall Rock',
        img: 'imgs/rocks/large-wall-rock.jpg',
        text: 'Used for ponds, walkways, large retaining walls & for a bridge over ditches. Lengths up to 15 feet and 6 feet wide.'
      }, {
        title: 'Chimley',
        img: 'imgs/rocks/chimley.jpg',
        text: 'Used as a cap for the top of chimneys.'
      }, {
        title: 'Landscape Boulders',
        img: 'imgs/rocks/boulder.jpg',
        text: 'Found all over Montana, these boulders come in a variety of colors, shapes and textures. Montana\'s cold winters and high altitude contribute to the unique decoration of lichens, moss and sometimes wild flowers found amongst it’s surfaces.'
      }
    ];
    this.getImage = function(src) {
      var style;
      style = void 0;
      style = {
        'background-image': 'url("' + src + '")'
      };
      return style;
    };
    this.goTo = function(location) {
      var distance, element, i, leapY, results, results1, speed, startY, step, stopY, timer;
      distance = void 0;
      element = void 0;
      i = void 0;
      leapY = void 0;
      results = void 0;
      results1 = void 0;
      speed = void 0;
      startY = void 0;
      step = void 0;
      stopY = void 0;
      timer = void 0;
      startY = document.body.scrollTop;
      element = document.getElementById(location);
      stopY = angular.element(element)[0].offsetTop;
      distance = stopY > startY ? stopY - startY : startY - stopY;
      if (distance < 100) {
        scrollTo(0, stopY);
      }
      speed = Math.round(distance / 100);
      if (speed >= 20) {
        speed = 20;
      }
      step = Math.round(distance / 25);
      leapY = stopY > startY ? startY + step : startY - step;
      timer = 0;
      if (stopY > startY) {
        i = startY;
        results = [];
        while (i < stopY) {
          setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
          leapY += step;
          if (leapY > stopY) {
            leapY = stopY;
          }
          timer++;
          results.push(i += step);
        }
        return results;
      } else {
        i = startY;
        results1 = [];
        while (i > stopY) {
          setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
          leapY -= step;
          if (leapY < stopY) {
            leapY = stopY;
          }
          timer++;
          results1.push(i -= step);
        }
        return results1;
      }
    };
    this.amHuman = false;
    this.showCaptcha = false;
    this.mailTo = function(subject) {
      if (this.amHuman === false) {
        this.showCaptcha = true;
        $scope.$watch((function() {
          return vcRecaptchaService.getResponse();
        }), function() {
          var url;
          url = void 0;
          if (vcRecaptchaService.getResponse() !== '') {
            url = 'http://api.montanastoneandboulders.com/verify';
            $http.get(url, {
              params: {
                key: '6LfY4LsdAAAAAATlDcA6OIG1GdhuRgF6Fdeq57o_',
                response: vcRecaptchaService.getResponse()
              }
            }).success(function(data) {
              if (data.status === 'valid') {
                localStorage.setItem('contactEmail', data.email);
                _mcThis.amHuman = true;
                _mcThis.showCaptcha = false;
                _mcThis.openMail(subject, true);
              }
            }).error(function(data) {
              console.log('Error', data);
            });
          }
        });
      } else {
        this.openMail(subject, false);
        return;
      }
    };
    this.openMail = function(subject, wait) {
      var element, email;
      element = void 0;
      email = void 0;
      email = localStorage.getItem('contactEmail');
      element = document.createElement('a');
      element.href = 'mailto:' + email + '?subject=' + subject;
      document.body.appendChild(element);
      if (wait === true) {
        $timeout((function() {
          return element.click();
        }), 1000);
      } else {
        element.click();
      }
      return true;
    };
  }
]);
